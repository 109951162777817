import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import PostService from "../services/post";

export default function NewTrails(props: any) {


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const {register, handleSubmit, watch, formState: {errors}} = useForm<PostInputs>();

    const [spaces, setSpaces] = useState('');

    type PostInputs = {
        name: string;
        province: string;
        directions: string
        location: string;
        difficulty: string;
        amenities: string;
        fee: string;
        trails: string;
        isDogFriendly:boolean;

    };


    const onSubmit: SubmitHandler<PostInputs> = (data: any) => {
        setError('');
        setLoading(true);
        data.isDogFriendly ==='true' ?data.isDogFriendly=true:false

        PostService.newTrails(data)
            .then(response => {
                setLoading(false);
                if (response.status === 200 || response.status === 201) {
                    setSuccess('Successfully submitted');

                }
            }).catch(error => {
            setLoading(false);
            if (error.response) setError(error.response); else setError("Something went wrong. Please try again later.");
        });
    }

    useEffect(() => {

    }, []);


    return (
        <>
            <div className="container-md top-border">

                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-6 ">
                        <form className="p-4 p-md-5 mt-4 border rounded-3 shadow-lg bg-light"
                              onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-floating mb-3">
                                <input  {...register("province", {required: true})} className="form-control h-100"
                                        id="floatingInput" placeholder="province..."/>
                                <label htmlFor="floatingInput">province</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea  {...register("name", {required: true})} className="form-control h-100"
                                        id="floatingInput" placeholder="name..."/>
                                <label htmlFor="floatingInput">name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea  {...register("directions", {required: true})} className="form-control h-100"
                                        id="floatingInput" placeholder="directions..."/>
                                <label htmlFor="floatingInput">directions</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea  {...register("location", {required: true})} className="form-control h-100"
                                        id="floatingInput" placeholder="location..."/>
                                <label htmlFor="floatingInput">location</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea  {...register("amenities", {required: false})} className="form-control h-100"
                                        id="floatingInput" placeholder="amenities..."/>
                                <label htmlFor="floatingInput">amenities</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea  {...register("difficulty", {required: true})} className="form-control h-100"
                                        id="floatingInput" placeholder="difficulty..."/>
                                <label htmlFor="floatingInput">difficulty</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea  {...register("fee", {required: true})} className="form-control h-100"
                                        id="floatingInput" placeholder="opinion..."/>
                                <label htmlFor="floatingInput">fee</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea  {...register("trails", {required: true})} className="form-control h-100"
                                        id="floatingInput" placeholder="opinion..."/>
                                <label htmlFor="floatingInput">trails</label>
                            </div>
                            <div className="form-floating mb-3">

                                <select  id="floatingInput" className="form-control h-100"  {...register("isDogFriendly", {required: true})} >
                                    <option value={'true'}>True</option>
                                    <option value={'false'}>False</option>
                                </select>

                                <label htmlFor="floatingInput">isDogFriendly</label>
                            </div>


                            <button className="w-100 btn btn-lg btn-primary"
                                    type="submit">{loading ? 'Loading...' : 'Post'}</button>
                            <hr className="my-4"/>
                            {!error ? "" : <div className="alert alert-danger" role="alert">{error}</div>}
                            `
                        </form>
                    </div>
                    <div className="col-md-2">

                    </div>
                </div>

            </div>

        </>

    );
}


