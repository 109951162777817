// @ts-ignore
import {ADD_LATEST_POST, DELETE_LATEST_POST} from '../actionTypes'

import { createSlice } from '@reduxjs/toolkit'

export const LatestPostSlice = createSlice({
    name: 'latestPost',
    initialState: {
        latest:null,
        lastUpdated: new Date()
    },
    reducers: {
        addLatest: (state,v:any) => {
            state.latest = v.payload.posts;
            state.lastUpdated =v.payload.updated
        },
        updateLastUpdated  : (state,lastUpdate:any ) => {
            state.lastUpdated =lastUpdate
        },
    },
})
export const { addLatest, updateLastUpdated } = LatestPostSlice.actions
export default LatestPostSlice.reducer
