import React from 'react';

export default function Footer() {


    return (
        <>
            <div className="container ">
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <div className="col-md-4 d-flex text-center align-items-center">

                        <span className="mb-3 mb-md-0 text-center  text-muted">  © 2022 The People's Opinion, <br />
                            <a href="https://tepo.info">TEPO</a> from <a target="_blank" href="https://unit326.com">Unit 326</a>,
                            <br />All Rights Reserved.
                        </span>
                    </div>

                    {/*<ul className="nav col-md-4 justify-content-end list-unstyled d-flex">*/}
                    {/*    <li className="">*/}
                    {/*        <h5>About us.</h5>*/}
                    {/*        <a className="text-muted" href="/about">*/}
                    {/*            What is TEPO?*/}
                    {/*        </a></li>*/}

                    {/*</ul>*/}


                    <ul className="nav  col-md-4 justify-content-end list-unstyled d-flex">
                        <li className="">
                            <h5>Connect.</h5>
                            <a className="text-muted" target="_blank" href="https://twitter.com/Unit_326">
                                <i className="fa fa-twitter"></i>
                            </a></li>

                    </ul>
                </footer>
            </div>
        </>

    );
}


