import http from "./http-common";

const getLatestPosts = async (page:number, limit:number) => {
    return http().get<any>(`post/latest?page=${page}&limit=${limit}`);
};

const getPopular = async () => {
    return http().get<any>(`post/popular`);
};

const newPost = async (post: any) => {
    return http().post<any>(`post/create`, post);
};

const newTrails = async (post: any) => {
    return http().post<any>(`trails/create`, post);
};

const getPost = async (req: any) => {
    return http().get<any>(`post/getPost?id=${req}`);
};

const viewPost = async (req: any) => {
    return http().get<any>(`post/view?id=${req}`);
};

const search = async (search: any, page:number, limit:number) => {
    return http().get<any>(`post/search?search=${search}&page=${page}&limit=${limit}`);
};

const newComment = async (data: any) => {
    return http().post<any>(`post/comment/create`, data);
};


const PostService = {
    getLatestPosts, newPost, newComment,
    getPost, search,viewPost,getPopular,newTrails
};
export default PostService;
