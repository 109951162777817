import axios, {AxiosInstance} from "axios";

export default function http(): AxiosInstance {
    // const token = localStorage.getItem("token");
    // const session = token ? JSON.parse(token).session : '';
    // const baseHost = process.env.NODE_ENV === "production" ? "https://api.tepo.info" : "http://localhost:5000";
    const baseHost =' https://api.tepo.info'
    return axios.create({
        baseURL: `${baseHost}/api/v1/`,
        headers: {
            'Content-Type': 'application/json'

        },
    })
};


