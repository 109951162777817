import React, { useEffect, useState } from "react";
function InterestCalculater() {
  const [result, setResult] = useState(0);
  const [principal, setPrincipal] = useState(0);
  const [rate, setRate] = useState(0);
  const [time, setTime] = useState(0);

  // This is just to show the async behaviour.
  const [test, setTest] = useState(0);

  function handleChange(e:any) {
    const { name, value } = e.target;
    // Putting "+" before a string converts it to a number ✨JS magic.
    // But here we're testing if it's a valid number. If not we're not going to set anything.
    if (Number.isNaN(+value)) {
      return;
    }

    // We are sure that the +value is a valid number so we're setting that to the state.
    if (name === "principal") {
      setPrincipal(+value);
    } else if (name === "rate") {
      setRate(+value);
    } else if (name === "time") {
      setTime(+value);
    }
  }

  // This effect will run everytime principal, rate or time changes.
  // We dont need to convert values since we're always storing numbers in the states.
  useEffect(() => {
    var theInterest = principal * (rate / 100) * time;
    setResult(theInterest);
  }, [principal, rate, time]);

    return (
        <>
          <div className="container">
            <h1>Calculate Simple Interest</h1>

            <button
              onClick={() => {
                setTest(test + 1);
                setTest(test + 1);
                setTest(test + 1);
              }}
            >
              test {test}
            </button>

            <label htmlFor="principal"> Initial Amount of Money: </label>
            <input
              onChange={handleChange}
              type="number"
              name="principal"
              step="any"
              placeholder="Enter Initial Amount"
              value={principal}
            />

            <label htmlFor="rate">Annual Interest Rate: </label>
            <input
              onChange={handleChange}
              type="number"
              name="rate"
              step="any"
              placeholder="Enter the rate"
              value={rate}
            />

            <label htmlFor="time">Time (in years):</label>
            <input
              onChange={handleChange}
              type="number"
              name="time"
              placeholder="Enter the period"
              value={time}
            />

            <h2>The interest is: {result}</h2>
          </div>
        </>

    );
}

export default InterestCalculater;
